<template>
    <div class="page">
        <div id="top" class="search_wap">
            <div class="a-inline-block">
                <a-input-search v-model="key" class="sInput" placeholder="请输入关键词" @search="onSearch"/>
            </div>
        </div>
        
        
        <div v-if="this.pagination.total" style="text-align: right;margin-right: 20px;">当前数据条数{{this.pagination.total}}条</div>
        <div v-infinite-scroll="getData"
               infinite-scroll-disabled="dataLoadStop"
               infinite-scroll-distance="50">
        <div class="lists" v-for="(item,index) in data" :key="index">
        <div class="item">
            <div>
                <img :src="ajaxUrl+'/scrm/index/getFile?id='+item.img" alt="" width="100px">
            </div>
            <div class="right">
                <div class="flex"> 
                    <!-- <span><label for="">编号：</label>{{item.id}}</span> -->
                    <span v-if="item.status==1"><a-icon type="check-circle" style="color:#009688;margin-right:3px"/>已启用</span>
                    <span v-if="item.status==2"><a-icon  type="close-circle" style="color:red;margin-right:3px"/>已禁用</span>
                    </div>
                <div><label for="">名称：</label>{{item.name}}</div>
                <div><label for="">单价：</label>￥{{item.unitprice}}</div>
                <!-- <div><label for="">销售数量：</label>{{item.salenum}}</div>
                <div><label for="">添加时间：</label>{{item.created}}</div> -->
            </div>
        </div>
            <div class="btn_con">
                <!-- <a-button type="primary" size="small" ghost @click="showDetail(item.id)">查看</a-button> -->
                <a-button type="primary" size="small" ghost @click="edit(item.id)">编辑</a-button>
                <!-- <a-popconfirm title="确定要关闭吗?"  @confirm="() => off(item.id)">
                    <a-button type="danger" size="small" ghost>关闭</a-button>
                </a-popconfirm> -->
                <a-popconfirm title="确定要删除吗?" @confirm="() => onDelete(item.id)">
                    <a-button type="danger" size="small" ghost>删除</a-button>
                </a-popconfirm>
            </div>
        </div>
             <!-- <div class="list-load-end" style="text-align:center;padding-top:25px;padding-bottom:50px">
                <span>{{dataLoadNomore ? "--没有更多数据了--" : "加载中..."}}</span>
            </div> -->
            
            <a-pagination v-if="pagination.total" style="text-align: center;" @change="onChanges" v-model="pagination.current" :total="pagination.total" />
            <div v-if="pagination.total==0" class="list-load-end" style="text-align:center;padding-top:25px;padding-bottom:50px">
                <span>--暂无数据--</span>
            </div>
        </div>


         <!-- <a-pagination
                v-if="data.length>0"
                class="page_select"
                v-model="pagination.current"
                :total="pagination.total"
                @change="pagesChange"
            /> -->
    </div>
</template>
<script>
// import $ from "jquery";
import {requestXml,getDateTime,ajaxUrl} from '../../../assets/js/request';
import infiniteScroll from "vue-infinite-scroll";

export default {
     directives: {infiniteScroll},  
    data() {
        return {
            // 搜索的内容
            key: "",
            data: [],
            ajaxUrl:ajaxUrl,
               // 分页
            dataLoadStop: false,  //列表数据加载停止状态
            dataLoadNomore: false,    //列表数据加载是否完成
            pagination: {
                current: 1,
                total: 100,
                pageSize: 10, // 默认每页显示数量
                showSizeChanger: true, // 显示可改变每页数量
               pageSizeOptions: ['10', '20', '50', '100'], // 每页数量选项
                showQuickJumper: true,
                // showTotal: total => `Total ${total} items`, // 显示总数
                onChange: (current) => this.pagesChange(current), //点击当前页码
                onShowSizeChange: (current, pageSize) => {
                    //设置每页显示数据条数，current表示当前页码，pageSize表示每页展示数据条数
                    console.log(pageSize);
                    this.showSizeChange(current, pageSize);
                },
            },
        };
    },
    
    beforeRouteEnter(to, from, next) {
      next(vm=>{
        vm.fromPath = from.path
      })
    },
    beforeDestroy(){
        sessionStorage.setItem('curpage',JSON.stringify({'search':this.key,'page':this.pagination}))
    },
    mounted: function(){
        
        if(this.fromPath == "/scrm_wap/clue/clueEdit") {
            var data=JSON.parse(sessionStorage.getItem('curpage'))
            sessionStorage.removeItem('curpage')
            this.pagination=data.page
            this.key=data.search
        }
        console.log(this.pagination.current);
    },
    methods:{
        onChanges(){
            window.location.href='#top'
            this.getData();
        },
        // 搜索
        onSearch(){
            this.data=[];
            this.dataLoadNomore=false;
            this.pagination.current=1;
            this.getData();
        },

          // 分页
        pagesChange(current) {
            //current参数表示是点击当前的页码，
            this.pagination.current = current;
            this.getData(); //向后端发送请求
        },
        showSizeChange(current, pageSize) {
            console.log(current, pageSize);
            this.pagination.current = current;
             this.pagination.pageSize = pageSize;
            this.getData(); //向后端发送请求
        },
        loadDataList(){
            console.log('asdasd');
        },
       getData() {
            this.dataLoadStop = true;
            requestXml("/jieb/Product/showproduct","POST",(res) => {
                for(let i=0;i<res.list.length;i++){
                    res.list[i].created = getDateTime(res.list[i].created);
                }
                this.data = res.list
                // this.data = this.data.concat(res.list);
                // if(res.page.maxpage>this.pagination.current){
                // this.pagination.current += 1
                // this.dataLoadStop = false
                // }else{
                // this.dataLoadNomore = true
                // }
                this.pagination.total = Number(res.page.total);
                this.pagination.pageSize = Number(res.page.pagesize);

            },{"search": this.key, page: {
                curpage: this.pagination.current,
                pagesize: this.pagination.pageSize,
            }})
        },
        // 产品禁用启用
        onChange(data){
            let id=data.id;
            let status=data.status;  //1启用 2禁用
            this.switchLoading=id;
            if(status==1){
                status=2;
            }else if(status==2){
                status=1;
            }
             requestXml("/jieb/Product/saveStatus","POST",(res) => {
                console.log(res)
                this.switchLoading='';
                this.getData();
            },{"id": id,'status':status})
        },
        // //添加产品
        //  addType(){
        //     this.$router.push({
        //         path: "/scrm_wap/goods/goodsAdd",
        //     });
        // },
        // 编辑
        edit(id){
            this.$router.push({
                path: "/scrm_wap/goods/goodsAdd",
                query: {
                    id: id,
                }
            });
        },
        // 删除
        onDelete(id){
            requestXml("/jieb/Product/delproduct","POST",(res) => {
                console.log(res);
                this.pagination.current=1;
                this.data=[];
                this.getData();
            },{"id": id})
        }
        
    }
};
</script>
<style scoped>
  .page_select {
        position: fixed;
        bottom: 0px;
        display: flex;
        justify-content: center;
        width: 100%;
        background: #fff;
    }
    .search_wap{
        text-align: left;
        margin-bottom: 15px;
        height: 40px;
        line-height: 40px;
        background-color: #fff;
    }
    .search_wap .ant-input-search{
        width: 90vw;
        margin: 0 5vw;
    }

    .lists{
        width: 92%;
        /* height: 26rem; */
        padding: 3rem;
        margin: 15px auto;
        background-color: #fff;
        border-radius: 10px;
        color: #202020;
    }
    /* .lists .list_row div{
        height: 5rem;
        line-height: 5rem;
        text-align: left;
    } */
    .lists .list_row .flex{
        justify-content: space-between;
        padding: 5px 0;
    }
    .lists .item{
        display: flex;
    }    
    .lists .right{
        text-align: left;
        padding-left: 20px;
        width: calc(100% - 100px );
    }
     .lists .right .flex{
         display: flex;
         justify-content: space-between;
     }
    .btn_con{
        text-align: left;
    }
    .btn_con button{
        color: #36A3FF;
        border-color: #36A3FF;
        margin: 1rem 1rem 0 0;
    }  

    
</style>
